<template>
  <div class="healthIQ">
    <!-- 卡片视图区域 -->
    <el-card>
      <el-row>
        <el-col class="tabbar">
          <div class="">
            <el-input
              placeholder="请输入题目名称"
              v-model="params.keyWord"
              clearable
              @clear="getUserList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchClick"
              ></el-button>
            </el-input>
            <el-cascader
              collapse-tags
              v-model="projectId"
              :options="dictList"
              :props="props"
              @change="handleChange"
              clearable
            ></el-cascader>
          </div>
          <div style="display: flex">
            <el-button
              v-if="orderStatus != 2"
              type="primary"
              size="medium"
              class="demo-input-label"
              @click="topicClick"
              >编辑选题</el-button
            >
            <el-button
              v-if="orderStatus != 2"
              type="primary"
              size="medium"
              class="demo-input-label"
              @click="isSequence = true"
              >编辑顺序</el-button
            >
            <el-button
              type="primary"
              size="medium"
              class="demo-input-label"
              v-if="userName == 'guanxing'"
              icon="el-icon-download"
              @click="sassOrderHealthExcelExportFile"
              >导出</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-card>
    <div class="main">
      <el-table :data="records" :height="tableHeight" border style="width: 98%">
        <el-table-column type="index" width="100" align="center">
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="questionNo"
          label="编号"
          width="260"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="question"
          label="题目"
          width="260"
        >
        </el-table-column>
        <el-table-column prop="question" label="是否正确" width="180">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.answerFlag == '1'">正确</el-tag>
            <el-tag type="warning" v-else>错误</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="answer"
          label="答案"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <pagination
        :pagingParams="params"
        :total="total"
        @pagingSizeChange="handleSizeChange"
        @pagingNumChange="handleCurrentChange"
      ></pagination>
    </div>

    <!-- 修改用户的对话框 -->
    <el-dialog
      title="订单信息"
      :visible.sync="isSequence"
      width="60%"
      @close="isSequence = false"
    >
      <div class="draggable">
        <draggable
          v-model="records1"
          chosenClass="chosen"
          forceFallback="true"
          group="people"
          animation="500"
        >
          <transition-group class="draggable_item">
            <block :span="8" v-for="element in records1" :key="element.id">
              <el-button
                style="
                  width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  margin-bottom: 5px;
                "
              >
                {{ element.question }}</el-button
              >
            </block>
          </transition-group>
        </draggable>
      </div>

      <div slot="footer">
        <el-button @click="sequenceClick">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import pagination from "@/components/common/pagination/Index.vue";
import { Decrypt } from "@/utils/common/encryp";
import draggable from "vuedraggable";
export default {
  name: "AdminDemoIndex",
  components: {
    pagination,
    draggable,
  },
  data() {
    return {
      isSequence: false,
      tableHeight: window.innerHeight - 350,
      props: {
        label: "name",
        value: "id",
        multiple: true,
        emitPath: true,
      },
      params: { pageNum: 1, pageSize: 5, keyWord: "" },
      records: [],
      total: 0,
      isIndeterminate: false,
      saveHealthIQ: {
        answer: "",
        answerFlag: 0,
        question: "",
        dictIds: [],
      },
      tree: [15014],
      addDialog: true,
      // 控制分配角色对话框的显示与隐藏
      setRoleDialogVisible: false,
      cascaderDictList: [],

      defaultChecked: [],
      projectId: [],
      dictList: [],
      userName: "",
      records1: [],
    };
  },

  mounted() {
    const { id, companyNo, status } = JSON.parse(Cookies.get("ORDER"));
    this.orderStatus = status;
    this.params.orderId = id;
    this.params.companyNo = companyNo;
    this.findList();
    this.findList1();
    this.getTagsList();
    const { userName } = JSON.parse(Cookies.get("userInfo"));
    console.log(userName);
    this.userName = userName;
  },
  methods: {
    // 放置
    drop(e, index) {
      console.log(e, index);
    },

    sequenceClick() {
      console.log(this.records1);
    },
    //标签刷选
    handleChange(val) {
      this.params.tags = val.map((item) => item[item.length - 1]);
      this.findList();
    },
    topicClick() {
      this.$router.push({
        path: "/business/order/companyOrder/Topic",
        name: "topic",
      });
    },
    async getTagsList() {
      const res = await this.$API.getDictList({ dictType: 1 });
      if (res.status !== 200) return "";
      this.dictList = res.data;
    },

    async sassOrderHealthExcelExportFile() {
      const { keyWord, orderId, companyNo, tags } = this.params;
      const res = await this.$API.sassOrderHealthExcelExportFile({
        keyWord,
        orderId,
        companyNo,
        tags,
      });
      const link = document.createElement("a");
      let blob = new Blob([res], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.download = "healthIQ.xlsx";
      document.body.appendChild(link);
      link.click();
    },

    searchClick() {
      this.params.pageNum = 1;
      this.findList();
    },
    handleSizeChange(newSize) {
      this.params.pageSize = newSize;
      this.findList();
    },
    handleCurrentChange(newPage) {
      this.params.pageNum = newPage;
      this.findList();
    },
    async findList() {
      const res = await this.$API.orderSelectHealthIQ(this.params);
      if (res.status == 200) {
        const { records, total } = JSON.parse(Decrypt(res.data));
        this.records = records;
        this.total = +total;
        console.log(this.total);
      }
    },
    async findList1() {
      const params = JSON.parse(JSON.stringify(this.params));
      params.pageNum = "";
      params.pageSize = "";
      const res = await this.$API.orderSelectHealthIQ(params);
      if (res.status == 200) {
        this.records1 = JSON.parse(Decrypt(res.data));
        // this.records = records;
        // this.total = +total;
        // console.log(this.total);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.healthIQ {
  width: 100%;
  background: #fff;
}
.draggable {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.draggable_item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
// .el-cascader {
//   width: 100%;
// }
.tabbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

// .el-breadcrumb {
//   margin-bottom: 20px;
// }
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}

.el-tag {
  margin: 7px;
}
.el-input {
  width: 300px;
  margin-right: 10px;
}
</style>
